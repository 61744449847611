import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import "./home.css";
import { GlobalService } from "../../service/globalService";
import { useNavigate, Navigate } from "react-router-dom";
import { Ripple } from "primereact/ripple";

import Axios from "axios";

export default function FilterMenuDoc() {
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const representatives = [
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Anna Fali", image: "annafali.png" },
    { name: "Asiya Javayant", image: "asiyajavayant.png" },
    { name: "Bernardo Dominic", image: "bernardodominic.png" },
    { name: "Elwin Sharvill", image: "elwinsharvill.png" },
    { name: "Ioni Bowcher", image: "ionibowcher.png" },
    { name: "Ivan Magalhaes", image: "ivanmagalhaes.png" },
    { name: "Onyama Limba", image: "onyamalimba.png" },
    { name: "Stephen Shaw", image: "stephenshaw.png" },
    { name: "XuXue Feng", image: "xuxuefeng.png" },
  ];

  const statuses = ["unqualified", "qualified", "new", "negotiation", "renewal", "proposal"];

  const paginatorLeft = (
    <Button
      type="button"
      className="">
      Previous{" "}
    </Button>
  );
  const paginatorRight = (
    <Button
      type="button"
      className="">
      Next{" "}
    </Button>
  );

  useEffect(() => {
    Axios.get(`${process.env.CMS_URL}/web/pages/planning/getLotameCustomAudience.jsp`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => {
        setCustomers(getCustomers(res.data));
        setLoading(false);
      })
      .catch((err) => console.log(err));

    // GlobalService.getCustomersLarge().then((data) => {
    //     setCustomers(getCustomers(data));
    //     setLoading(false);
    // });
    initFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.lastModifiedDate = new Date(d.lastModifiedDate);
      return d;
    });
  };

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // const formatCurrency = (value) => {
  //     return value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //     });
  // };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "country.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      representative: { value: null, matchMode: FilterMatchMode.IN },
      lastModifiedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      balance: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-replay"
          className="p-button-outlined"
          onClick={clearFilter}
          title="Clear Table Filters"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2">{rowData.audienceSize}</span>
      </React.Fragment>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"></Button>
    );
  };

  const filterFooterTemplate = () => {
    return <div className="px-3 pt-0 pb-3 text-center font-bold">Customized Buttons</div>;
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2">{representative.name}</span>
      </React.Fragment>
    );
  };

  const representativeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="vertical-align-middle ml-2">{option.name}</span>
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.lastModifiedDate);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const balanceBodyTemplate = (rowData) => {
    // return formatCurrency(rowData.balance);
  };

  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const activityBodyTemplate = (rowData) => {
    return (
      <ProgressBar
        value={rowData.activity}
        showValue={false}></ProgressBar>
    );
  };

  const activityFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <Slider
          value={options.value}
          onChange={(e) => options.filterCallback(e.value)}
          range
          className="m-3"></Slider>
        <div className="flex align-items-center justify-content-between px-2">
          <span>{options.value ? options.value[0] : 0}</span>
          <span>{options.value ? options.value[1] : 100}</span>
        </div>
      </React.Fragment>
    );
  };

  const verifiedBodyTemplate = (rowData) => {
    return (
      // <i
      //     className={classNames("pi", {
      //         "true-icon pi-check-circle": rowData.verified,
      //         "false-icon pi-times-circle": !rowData.verified,
      //     })}
      // ></i>
      <>
        <Button icon="pi pi-pencil"></Button>
        <Button icon="pi pi-trash"></Button>
      </>
    );
  };

  const verifiedFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  const header = renderHeader();
  let navigate = useNavigate();

  const navigateTo = () => {
    navigate("/dashboard");
    <Navigate to={`/dashboard`} />;
  };

  const template1 = {
    layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className="pi pi-angle-left"></span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className="pi pi-angle-right"></span>
          <Ripple />
        </button>
      );
    },
  };
  const isLocal = localStorage.getItem("nameval");

  return (
    <>
      {isLocal && (
        <>
          <div className="container">
            <div className="viewadsbtn audienceBtn">
              <h3>Audience List</h3>
              <Button onClick={navigateTo}> Create Audience</Button>
            </div>
            <div className="datatable-filter-demo">
              <div className="card datatable-demo">
                <DataTable
                  value={customers}
                  paginator
                  className="p-datatable-customers"
                  // showGridlines
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  filterDisplay="menu"
                  loading={loading}
                  responsiveLayout="scroll"
                  // sortMode="multiple"
                  paginatorTemplate={template1}
                  // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rowsPerPageOptions={[10, 20, 50]}
                  paginatorLeft={paginatorLeft}
                  paginatorRight={paginatorRight}
                  globalFilterFields={["campaignName", "type", "objective", "budget", "noOfDays"]}
                  header={header}
                  emptyMessage="No customers found.">
                  <Column
                    field="campaignName"
                    header="Name"
                    filterPlaceholder="Search by name"
                    sortable
                  />
                  {/* <Column
                            header="Audience Size"
                            field="audienceSize"
                            // filterField=""
                            body={countryBodyTemplate}
                            sortable
                        /> */}
                  <Column
                    header="Type"
                    field="type"
                    filterMenuStyle={{ width: "10rem" }}
                    // body={representativeBodyTemplate}
                    // sortable
                  />
                  {/* <Column
                            header="Updated Date"
                            // field="lastModifiedDate"
                            filterField="lastModifiedDate"
                            dataType="date"
                            body={dateBodyTemplate}
                            filter
                            filterElement={dateFilterTemplate}
                        /> */}
                  <Column
                    header="Objective"
                    field="objective"
                    dataType="numeric"
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    header="Budget"
                    field="budget"
                    dataType="numeric"
                    sortable
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    header="Duration"
                    field="noOfDays"
                    dataType="numeric"
                    sortable
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    field="Actions"
                    header="Actions"
                    dataType="boolean"
                    bodyClassName="text-center"
                    body={verifiedBodyTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
