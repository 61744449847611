import React from "react";
import { useEffect } from 'react';

import { Route, Routes, BrowserRouter as Router, Navigate} from "react-router-dom";
import Home from "./Pages/Home/home";
import Audience from "./Pages/Audience/audience";
import Navbar from "./components/navbar/navbar";
import Login from "./Pages/Login/login"
import Dashboard from "./Pages/Dashboard/dashboard";
import WebsitePlanning from "./Pages/WebsitePlanning/websitePlanning";
import AudiencePlanning from "./Pages/audiencePlanning/audiencePlanning"

const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem('nameval');

  useEffect(() => {
    // Your logic to conditionally render Navbar based on the route or other conditions
  }, [window.location.pathname, isAuthenticated]);

  return (
    <Router>
      { window.location.pathname !== '/login' && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? '/home' : '/login'} />}
        />
                    <Route path="/home" element={<Home />} />

        {/* Add conditional rendering for secured routes */}
        {isAuthenticated && (
          <>

            <Route path="/create-audience" element={<Audience />} />
            <Route path="/home/update-audience" element={<Audience />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/websitePlanning" element={<WebsitePlanning />} />
            <Route path="/audiencePlanning" element={<AudiencePlanning />} />
          </>
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
