//Data Layer
import React, { useState, useEffect, useRef } from "react";
import AccordionDemo from "../../components/nestedCard/nestedCard";
import "./audience.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "../../components/dropdown/dropdown";
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
import AudienceNewRow from "../../components/audienceRow/audienceRow";
import { ProgressSpinner } from "primereact/progressspinner";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setIsCheck, deleteIsCheck } from "../../store/features/dropdown/dropdownSlice";
import { setChecks } from "../../store/features/dropdown/checks";
import { SelectButton } from "primereact/selectbutton";
import { uuid } from "uuidv4";

export default function Audience() {
  const [popupActive, setPopupActive] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [getOrAnd, setOrAnd] = useState([]);
  const [getExclude, setExclude] = useState([]);
  const [audienceName, setAudienceName] = useState("");
  const [impression, setImpression] = useState("0");
  let [boxstate, setBoxState] = useState(false);
  let [valid, setValid] = useState(false);
  let [validSave, setValidSave] = useState(false);
  let [loading, setLoading] = useState(false);
  // let [check, setIsCheck] = useState([]);
  const million = 1000000;
  const [millionConvertedtraiReach, setmillionConvertedtraiReach] = useState("0");
  const [millionConvertedcompositionIndexUv, setmillionConvertedcompositionIndexUv] = useState("0");
  const [millionConvertedavgMinVisitor, setmillionConvertedavgMinVisitor] = useState("0");

  const isCheck = useSelector((state) => state.dropdown);
  const dispatch = useDispatch();
  const geoBehaviors = isCheck.filter((behavior) => behavior.behaviorType === "geo");
  const genderBehaviors = isCheck.filter((behavior) => behavior.behaviorType === "gender");
  const lotameAudienceBehaviors = isCheck.filter((behavior) => behavior.behaviorType === "affinity");
  const customAudienceBehaviors = isCheck.filter((behavior) => behavior.behaviorType === "website");
  const ageBehaviors = isCheck.filter((behavior) => behavior.behaviorType === "age");

  const [inputval, setInputVal] = useState({ noOfDays: "", budget: "", campaignName: "", objective: "" });

  const popupOpen = (e) => {
    if (popupActive) {
      setPopupActive(false);
    } else {
      setPopupActive(true);
    }
  };

  const changeData = (e) => {
    const { name, value } = e.target;
    setInputVal({ ...inputval, [name]: value });
  };
  // Or And Variables
  const options = ["AND", "OR"];
  const [value, setValue] = useState(options[0]);
  const [value1, setValue1] = useState(options[0]);

  const toast = useRef(null);

  const includeSections = [geoBehaviors, genderBehaviors, lotameAudienceBehaviors, customAudienceBehaviors, ageBehaviors];
  const excludeSections = [geoBehaviors, genderBehaviors, lotameAudienceBehaviors, customAudienceBehaviors, ageBehaviors];

  useEffect(() => {
    // console.log(includeSections, "isCheck");
    // if (selectedCity != null && .length > 0) {
    //   if (audienceName != "") {
    //     setValidSave(true);
    //   }
    //   setValid(true);
    // } else {
    //   valid = false;
    // }
  }, []);

  // ########## And /Or Block ###############
  const getAccordrowData = (data) => {
    setOrAnd(data);
  };

  const handelDelete = (id) => {
    // const newIsCheck = isCheck.filter((val) => val.behaviorId !== id);
    // console.log("isCheckisCheckisCheck", newIsCheck)

    // console.log("gotcallfordelete",id)
    dispatch(deleteIsCheck(id));

    // const newData = getOrAnd.filter((val) => val.behaviorId !== id);    // // console.log("abccccc", newData);
    // setOrAnd(newData);
    // let isCheckarr = [];
    // newData.forEach((element) => {
    //   isCheckarr.push(element.behaviorId);
    // });
    // setIsCheck(isCheckarr);
  };

  // console.log("a",getOrAnd);
  // End #########   And /Or Block ###############
  const getExcludeAccData = (data) => {
    setExclude(data);
  };

  // ############ Exclude block ################

  const calculate = async () => {
    let _behaviorList = [...isCheck.filter((items) => items.isInclude == true)];
    let _excludeBehaviorList = [...isCheck.filter((items) => items.isInclude == false)];

    console.log(isCheck, _behaviorList);

    let _data = {
      // uuid: "aa65bcdb-45db-4a48-a94e-8b69d5a42feb",
      behaviors: _behaviorList,
      excludeBehaviors: _excludeBehaviorList,
      noOfDays: inputval?.noOfDays,
      budget: inputval?.budget,
    };
    // let _data = {
    //   operator: selectedCity.name,
    //   behaviors: _behaviorList,
    //   excludeBehaviors: _excludeBehaviorList,
    // };

    let data = JSON.stringify(_data);
    console.log(_data, _excludeBehaviorList, "toCalc", _behaviorList, "behaviour List");
    setLoading(true);
    Axios.post(`${process.env.CMS_URL}/web/pages/planning/plannerDataLayerResponse.jsp`, data)
      .then((res) => {
        console.log("abccal", res.data);
        // toast.current.show({severity:'success', summary: 'Success Message', detail:'Message Content', life: 3000});

        setImpression(res?.data?.trai_reach);
        if (Number(res.data.trai_reach) >= million) {
          const formattedNum = (Number(res.data.trai_reach) / million).toFixed(2);
          setmillionConvertedtraiReach(formattedNum);
        } else {
          // console.log("impression", res.data);
        }
        // if (Number(res.data.comosition_index_uv) >= million) {
        // const formattedNum = (Number(res.data.comosition_index_uv) / million).toFixed(2);
        setmillionConvertedcompositionIndexUv(res.data.comosition_index_uv);
        // }
        // if (Number(res.data.avg_min_visitor) >= million) {
        // const formattedNum = (Number(res.data.avg_min_visitor) / million).toFixed(2);
        setmillionConvertedavgMinVisitor(res.data.avg_min_visitor);
        // }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success Message",
      detail: "Message Content",
      life: 3000,
    });
  };

  const onSave = async () => {
    let _behaviorList = [...getOrAnd];
    let _excludeBehaviorList = [...getExclude];
    console.log("test");
    // if (audienceName === "" && impression === 0) {
    //   return;
    // }

    let _data = {
      // audienceName: audienceName,
      // totalImpression: impression,
      // operator: selectedCity.name,
      noOfDays: inputval?.noOfDays,
      budget: inputval?.budget,
      objective: inputval?.objective,
      campaignName: inputval?.campaignName,
      type: "data layer",
      audienceId: uuid(new Date()),
      traiReach: "465.0",
      compositionIndexUv: "33.4",
      avgMinVisitor: "2.0",
      operator: "AND",
      behaviors: _behaviorList,
      excludeBehaviors: _excludeBehaviorList,
    };

    let data = JSON.stringify(_data);
    console.log("saved", data, audienceName, impression);

    Axios.post(`${process.env.CMS_URL}/web/pages/planning/saveLotameCustomAudience.jsp`, data)
      .then((res) => {
        console.log("abccal", res.data);
        popupOpen();
        if (res?.data) {
          toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Audience Saved Successfully",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="mainWrap">
        <div className="newAudDetailHeader">
          <div className="newAudDetailList">
            {/* <InputText
              className="newAudDetailInput"
              value={audienceName}
              onChange={(e) => setAudienceName(e.target.value.toUpperCase())}
              placeholder="Enter Name"
              title="click to Edit"
            /> */}
            {/* <div className="form-group modelinput">
              <InputTextarea
                className="form-control"
                id="model"
                placeholder="Enter Short description"
              /> */}
            {/* <span>140 charactes left.</span> */}
            {/* </div> */}

            <InputText
              className="newAudDetailInput"
              type="text"
              value={inputval?.noOfDays}
              name="noOfDays"
              placeholder="noOfDays"
              onChange={changeData}
            />

            <InputText
              className="newAudDetailInput"
              type="text"
              value={inputval?.budget}
              name="budget"
              placeholder="budget"
              onChange={changeData}
            />
          </div>
          <h3>
            <span>
              <small>Trai Reach : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedtraiReach
              )}
              M
            </span>
            <span>
              <small>Composition Index UV : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedcompositionIndexUv
              )}
            </span>
            <span>
              <small>AVG Min Visitor : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedavgMinVisitor
              )}
            </span>
            {/* <span>Total Impression</span> */}
          </h3>
          <div className="AudSaveCancelBtn">
            <Button
              className="btn btn-primary commonbtn btnDiscard waves-effect waves-light"
              label="Clear"
              type="submit"
              onClick={() => {
                dispatch(setIsCheck([]));
                dispatch(setChecks([]));
              }}
            />
            <Button
              className="btn btn-primary commonbtn waves-effect waves-light"
              label="Save"
              type="submit"
              onClick={popupOpen}
            />
          </div>
        </div>

        {/* section 2 */}
        <div className="detailsMainWrap">
          {includeSections.map((val, index) => {
            return (
              <div
                className="detailListWrap"
                key={index}>
                {/* include containers */}
                <div className="cardMainWrap">
                  <div className="card">
                    {/* <div>
            <AccordionDemo isListData={getAccordrowData} excludeList={getExcludeAccData} isExclude={boxstate} isChecknew={check}  />
            </div> */}
                    <div onClick={() => setBoxState(false)}>
                      <Dropdown
                        n={index}
                        type={true}
                        operator={index == 3 ? value1 : index == 2 ? value : "AND"}
                      />
                    </div>
                  </div>
                </div>
                <div className="cardOverviewWrap">
                  <div
                    id="attributeOrAndWrap"
                    className={boxstate ? "" : "activeTab"}
                    onClick={() => {
                      setBoxState(false);
                      console.log(boxstate);
                    }}>
                    <div
                      id="operatorDDWrap"
                      className="operatorDDWrap">
                      {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" className='dropDown' placeholder="OR / AND"></Dropdown> */}
                    </div>
                    <div>
                      <div className={index == 2 ? "flex toggleBtn" : "flex hide toggleBtn"}>
                        <SelectButton
                          value={value}
                          onChange={(e) => {
                            setValue(e.value);
                            console.log(index);
                          }}
                          options={options}
                        />
                      </div>
                      <div className={index == 3 ? "flex toggleBtn" : "flex hide toggleBtn"}>
                        <SelectButton
                          value={value1}
                          onChange={(e) => {
                            setValue1(e.value);
                            console.log(index);
                          }}
                          options={options}
                        />
                      </div>
                      {includeSections[index] && includeSections[index].length ? "" : <span className="emptyList">No lists</span>}
                      {includeSections[index] &&
                        includeSections[index].map((val, index) => {
                          return (
                            <React.Fragment key={index}>
                              {val.isInclude == true ? (
                                <AudienceNewRow
                                  name={val.behaviorName}
                                  id={val.behaviorId}
                                  handleDelete={() => handelDelete(val.behaviorId)}
                                />
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>

                {/* exclude containers */}
                <div className="cardMainWrap">
                  <div className="card">
                    {/* <div>
            <AccordionDemo isListData={getAccordrowData} excludeList={getExcludeAccData} isExclude={boxstate} isChecknew={check}  />
            </div> */}
                    <div onClick={() => setBoxState(true)}>
                      <Dropdown
                        n={index}
                        type={false}
                        operator="AND"
                      />
                    </div>
                  </div>
                </div>
                <div className="cardOverviewWrap">
                  <div
                    id="attributeOrAndWrap"
                    className={boxstate ? "activeTab" : ""}
                    onClick={() => {
                      setBoxState(true);
                      console.log(boxstate);
                    }}>
                    <div
                      id="operatorDDWrap"
                      className="operatorDDWrap">
                      {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" className='dropDown' placeholder="OR / AND"></Dropdown> */}
                    </div>
                    <div>
                      {/* <div className="flex justify-content-center">
                        <SelectButton
                          value={value}
                          onChange={(e) => setValue(e.value)}
                          options={options}
                        />
                      </div> */}

                      {excludeSections[index] && excludeSections[index].length ? (
                        <>
                          {excludeSections[index] &&
                            excludeSections[index].map((val, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {val.isInclude == false ? (
                                    <AudienceNewRow
                                      name={val.behaviorName}
                                      id={val.behaviorId}
                                      handleDelete={() => handelDelete(val.behaviorId)}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            })}{" "}
                        </>
                      ) : (
                        <span className="emptyList">No lists</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* section 2 closed */}

        <div className="operationBtn">
          <Button
            className="btn btn-primary waves-effect waves-light"
            label="Calculate"
            type="submit"
            onClick={calculate}
            // disabled={!valid}
          />
        </div>
        <div className={`savePopup  ${popupActive ? "active" : ""}`}>
          <div className="newAudDetailList ">
            <i
              className="pi-times pi"
              onClick={popupOpen}
              style={{ fontSize: "1rem" }}></i>
            <InputText
              className="newAudDetailInput"
              type="text"
              name="campaignName"
              placeholder="campaignName"
            />

            <InputText
              className="newAudDetailInput"
              type="text"
              name="objective"
              placeholder="objective"
            />
            <div className="AudSaveCancelBtn">
              <Button
                className="btn btn-primary commonbtn waves-effect waves-light"
                label="Save"
                type="submit"
                onClick={onSave}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
