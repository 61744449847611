import React, { ReactDOM, useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import "./dropdown.css";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setIsCheck } from "../../store/features/dropdown/dropdownSlice";
import { setChecks } from "../../store/features/dropdown/checks";

export const Dropdown = (props) => {
  // const [data, setData] = useState([]);
  // let [isCheck, setIsCheck] = useState([]);
  const [data, setData] = useState([]);
  // const [checkedIds, setCheckedIds] = useState([]);

  const checkedIds = useSelector((state) => state.checks);
  const isCheck = useSelector((state) => state.dropdown);
  const dispatch = useDispatch();
  const refetch = true;

  useEffect(() => {
    Axios.get(`${process.env.CMS_URL}/web/pages/planning/getLotameBehaviors.jsp`)
      .then((res) => {
        // console.log(props.n, "abc", [res.data[0]]);
        setData([res.data[props.n]]);
      })
      .catch((err) => console.log(err));
  }, []);

  const onCheckChange = (e, behavior) => {
    // let _isCheck = [...isCheck];
    // if (e.checked) {
    //   _isCheck.push(e.value);
    // } else _isCheck.splice(_isCheck.indexOf(e.value), 1);
    // setIsCheck(_isCheck);
    // console.log(isCheck)

    // let _isCheck = [...isCheck];
    // if (e.checked) {
    //   _isCheck.push(e.value);
    // } else _isCheck.splice(_isCheck.indexOf(e.value), 1);
    // dispatch(setIsCheck(_isCheck));
    console.log(props, "props");
    let _isCheck = [...isCheck];
    if (e.checked) {
      if (props.operator) {
        behavior.operator = props.operator;
      }
      if (behavior?.behaviors) {
        delete behavior.behaviors;
      }
      behavior.isInclude = props.type;
      _isCheck.push(behavior);
    } else {
      _isCheck = _isCheck.filter((item) => {
        return item.behaviorId !== behavior.behaviorId;
      });
    }
    const ids = _isCheck.map((item) => item.behaviorId);
    // setCheckedIds(ids);
    // console.log(checkedIds, isCheck, "checkedIds");
    dispatch(setChecks(ids));
    dispatch(setIsCheck(_isCheck));
  };

  return (
    <div className="accordion-demo">
      <div className="accordionMainWrap">
        {data &&
          data.map((val) => {
            return (
              <div
                key={val.id}
                className="accordWrap">
                <Accordion>
                  <AccordionTab
                    expandIcon="pi pi-plus"
                    collapseIcon="pi pi-minus"
                    header={
                      <React.Fragment>
                        <span>{val.behaviorType}</span>
                      </React.Fragment>
                    }>
                    <div className="AccCopy">
                      {val.data &&
                        val.data.map((lvl2) => {
                          return (
                            <div key={lvl2.behaviorId}>
                              <Accordion>
                                <AccordionTab
                                  header={
                                    <React.Fragment>
                                      <Checkbox
                                        value={lvl2.behaviorId}
                                        onChange={(e) => {
                                          onCheckChange(e, lvl2);
                                        }}
                                        checked={checkedIds.includes(lvl2.behaviorId)}
                                        disabled={checkedIds.includes(lvl2.behaviorId)}></Checkbox>
                                      <span>{lvl2.behaviorName}</span>
                                    </React.Fragment>
                                  }>
                                  {lvl2.behaviors &&
                                    lvl2.behaviors.map((lvl3) => {
                                      return (
                                        <div key={lvl3.behaviorId}>
                                          <Accordion>
                                            <AccordionTab
                                              header={
                                                <React.Fragment>
                                                  <Checkbox
                                                    value={lvl3.behaviorId}
                                                    onChange={(e) => {
                                                      onCheckChange(e, lvl3);
                                                    }}
                                                    checked={checkedIds.includes(lvl3.behaviorId)}
                                                    disabled={checkedIds.includes(lvl3.behaviorId)}></Checkbox>
                                                  <span>{lvl3.behaviorName}</span>
                                                </React.Fragment>
                                              }>
                                              {lvl3.behaviors &&
                                                lvl3.behaviors.map((lvl4) => {
                                                  return (
                                                    <div key={lvl4.behaviorId}>
                                                      <Accordion>
                                                        <AccordionTab
                                                          header={
                                                            <React.Fragment>
                                                              <Checkbox
                                                                value={lvl4.behaviorId}
                                                                onChange={(e) => {
                                                                  onCheckChange(e, lvl3);
                                                                }}
                                                                checked={checkedIds.includes(lvl4.behaviorId)}
                                                                disabled={checkedIds.includes(lvl4.behaviorId)}></Checkbox>
                                                              <span>{lvl4.behaviorName}</span>
                                                            </React.Fragment>
                                                          }></AccordionTab>
                                                      </Accordion>
                                                    </div>
                                                  );
                                                })}
                                            </AccordionTab>
                                          </Accordion>
                                        </div>
                                      );
                                    })}
                                </AccordionTab>
                              </Accordion>
                            </div>
                          );
                        })}
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            );
          })}
      </div>
    </div>
  );
};
