import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "./audiencePlanning.css";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { uuid } from "uuidv4";

// const dummyData = [
//   { age: "", gender: "", state: "", website: "" },
//   // Add more dummy data here as needed
// ];

const App = () => {
  const [showAge, setShowAge] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [showStates, setShowStates] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [body, setBody] = useState([]);

  const toast = useRef(null);

  // const [queryParams, setQueryParams] = useState("");
  let queryParams = "";

  const [selectedGender, setSelectedGender] = useState("");
  //const [selectedAge, setSelectedAge] = useState("All Age Group");
  const [selectedAffinity, setSelectedAffinity] = useState("");
  const [selectedWebsite, setSelectedWebsite] = useState("");
  const [selectedGeo, setSelectedGeo] = useState("");
  const [selectedAge, setSelectedAge] = useState("");

  const [completeData, setCompleteData] = useState("");
  const [audienceData, setAudienceData] = useState("");

  const [selectedRows, setSelectedRows] = useState();

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox.after("All Gender");
  };

  const [campaignPlanObj, setCampaignPlanObj] = useState({ campaignName: "", noOfDays: "", objective: "", budget: "" });

  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isFormComplete2, setIsFormComplete2] = useState(false);

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setCampaignPlanObj({ ...campaignPlanObj, [name]: value });

    if ((name === "noOfDays" || name === "budget") && value !== "") {
      setIsFormComplete(true);
    } else {
      const allFieldsFilled = Object.values(campaignPlanObj).every((value) => value !== "");
      setIsFormComplete2(allFieldsFilled);
    }
  };

  const OnSubmit = async () => {
    console.log(campaignPlanObj, "test");
    const TableData = Array.isArray(selectedRows)
      ? selectedRows.map((a) => {
          return {
            type: "website",
            ageRange: a.age,
            gender: a.gender,
            geo: a.state,
            webiste: a.website,
            affinity: "",
          };
        })
      : [];
    campaignPlanObj.referenceNo = uuid(new Date());
    const Compainobj = { campaignPlanDetails: TableData, campaignPlan: campaignPlanObj };
    console.log(Compainobj, "CompainData");
    console.log(selectedRows);

    let data = JSON.stringify(Compainobj); // Corrected the variable name to Compainobj
    console.log("saved", Compainobj);

    Axios.post(`${process.env.CMS_URL}/web/pages/planning/plannerCampaignDetailsSubmit.jsp`, data)
      .then((res) => {
        console.log("abccal", res.data);
        if (res?.data) {
          toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Audience Saved Successfully",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  }; // Removed the extra closing brace here

  const onSelectionChange = (e) => {
    setSelectedRows(e.value);
    //console.log(e.value);
  };

  const handleRowSelect = (filteredData) => {
    // Log the selected row data to the console
    console.log("Selected Row:", filteredData);
  };

  const location = useLocation();
  const path = location.pathname;
  // const queryParams = new URLSearchParams(location.search);
  // queryParams.append("name", "true");
  // console.log(queryParams, 'query');
  // console.log(path)
  useEffect(() => {
    getData(path);
  }, [path]);

  const addQueryParams = (type, val) => {
    const searchParams = new URLSearchParams();
    if (showAge && showGender && showStates && showWebsite) {
      searchParams.append("age_group_check", true);
      searchParams.append("gender_check", true);
      searchParams.append("state_check", true);
      searchParams.append("website_check", true);
    } else if (showAge && showGender && showStates) {
      searchParams.append("age_group_check", true);
      searchParams.append("gender_check", true);
      searchParams.append("state_check", true);
    } else if (showAge && showGender) {
      searchParams.append("gender_check", true);
      searchParams.append("age_group_check", true);
    } else if (showAge) {
      searchParams.append("age_group_check", true);
    }
    console.log(searchParams.toString());
    queryParams = "&" + searchParams.toString();
  };

  function convertData(inputData) {
    const result = {};

    inputData.forEach((item) => {
      const key = item.behaviorType;
      const values = item.data.map((behavior) => behavior.behaviorName);
      console.log(
        values,
        "values",
        item.data.map((item) => item.behaviorName)
      );
      result[key] = values;
    });

    return result;
  }
  const getData = async (type) => {
    if (type === "/websitePlanning") {
      var audience = await fetch(`${process.env.CMS_URL}/web/pages/planning/plannerInputDropdownResponse.jsp?type=website`)
        .then((res) => res.json())
        .then((data) => {
          setAudienceData(data);
          console.log(data);
        });
    } else {
      var audience = await fetch(`${process.env.CMS_URL}/web/pages/planning/getLotameBehaviors.jsp`)
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "abctest");
          const convertedData = convertData(data);
          console.log(convertedData, "convertedData");
          setAudienceData(convertedData);
          setCompleteData(data);
        });
    }
    // return
  };

  const getTableDataWebsite = async () => {
    // var tableData = await fetch(
    //   `${process.env.CMS_URL}/web/pages/planning/plannerInputWebsiteResponse.jsp?age_group=` +
    //     selectedAffinity +
    //     "&website=" +
    //     selectedWebsite +
    //     "&state=" +
    //     selectedStates +
    //     "&gender=" +
    //     selectedGender +
    //     "" +
    //     queryParams +
    //     ""
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setFilteredData(data);
    //   });
  };

  const [behaviorValue, setBehaviorValue] = useState("");
  const [showData, setShowData] = useState(false);

  const ShowAudiance = () => {
    getTableDataSection();
    setShowData(true);
  };

  const getTableDataSection = async () => {
    // //console.log(selectedAge.toString(), 'abc');
    body.forEach((item) => {
      delete item.behaviors;
      item.operator = "OR";
      item.isInclude = true;
    });
    console.log(body);

    const dataBody = { noOfDays: campaignPlanObj.noOfDays, budget: campaignPlanObj.budget, behaviors: body, excludeBehaviors: [] };

    console.log(JSON.stringify(dataBody));

    Axios.post(`${process.env.CMS_URL}/web/pages/planning/getLotameUniqueness.jsp`, JSON.stringify(dataBody))
      .then((res) => {
        console.log("abccal", res.data);
        setBehaviorValue(res.data.toFixed(2));
        if (res?.data) {
          toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Audience Saved Successfully",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(filteredData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "products");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  const onInput = (behaviorType, behaviorName) => {
    setBody((prevBody) => {
      const updatedBodySet = new Set([...prevBody]);

      completeData.forEach((item) => {
        // console.log(item, behaviorName, 'item');
        let val = [];

        if (item.behaviorType === behaviorType) {
          behaviorName.forEach((vals) => {
            const data = item.data.filter((value) => value.behaviorName === vals);
            // console.log(vals, item.data, data, 'vals');
            val.push(data[0]);
          });

          // console.log(val, 'vvvvvv');
          val.forEach((value) => updatedBodySet.add(value)); // Add unique values to the Set
          console.log(val, updatedBodySet, "vvvvvv");
        }
      });

      const updatedBodyArray = [...updatedBodySet]; // Convert Set to Array
      console.log(updatedBodyArray, "vvvvvv");

      return updatedBodyArray;
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container inner-page-site">
        <div className="viewadsbtn audienceBtn">
          <h3>Audience Planning</h3>
        </div>
        <div className="row">
          <MultiSelect
            options={audienceData?.age}
            value={selectedAge}
            onChange={(e) => {
              setSelectedAge(e.value);
              onInput("age", e.target.value);
            }}
            placeholder="Select Age"
            optionLabel=""
            className="dropdown"
            multiple
            display="chip"
            maxSelectedLabels={3}
          />
          <MultiSelect
            value={selectedGender}
            options={audienceData?.gender}
            onChange={(e) => {
              setSelectedGender(e.value);
              onInput("gender", e.target.value);
            }}
            placeholder="Select Gender"
            optionLabel=""
            multiple
            className="dropdown"
            display="chip"
            maxSelectedLabels={3}
            onShow={onShow}
          />

          {/* <MultiSelect
          options={audienceData?.Website}
          value={selectedStates}
          onChange={(e) => setSelectedStates(e.value)}
          placeholder="Select Website"
          optionLabel=""
          className="dropdown"
          multiple
          display="chip" 
          maxSelectedLabels={3} 
        /> */}

          <MultiSelect
            options={audienceData?.geo}
            value={selectedGeo}
            onChange={(e) => {
              setSelectedGeo(e.value);
              onInput("geo", e.target.value);
            }}
            placeholder="Select Geo"
            optionLabel=""
            className="dropdown"
            multiple
            display="chip"
            maxSelectedLabels={3}
          />
          <MultiSelect
            value={selectedAffinity}
            options={audienceData?.Affinity}
            onChange={(e) => {
              setSelectedAffinity(e.value);
              onInput("Affinity", e.target.value);
            }}
            placeholder="Select Affinity"
            optionLabel=""
            multiple
            className="dropdown"
            display="chip"
            maxSelectedLabels={3}
          />
          {/* {path !== "/websitePlanning" ? (
          <MultiSelect
            value={selectedWebsite}
            options={audienceData?.website}
            display="chip" 
            maxSelectedLabels={3} 
            onChange={(e) =>{ setSelectedWebsite(e.value)
              addQueryParams();
              getTableDataWebsite();
            
         }
        }
            placeholder="Select Website"
            optionLabel=""
            className="dropdown"
          />
        ) : ( path 
      //&&        <MultiSelect
      //     value={selectedSection}
      //     options={audienceData?.section}
      //     display="chip" 
      //     maxSelectedLabels={3} 
      //     onChange={(e) =>{ setSelectedSection(e.value)
      //       addQueryParams();
      //       // getTableDataWebsite();
      //       getTableDataSection()
          
      //  }
      // }
      //     placeholder="Select Section"
      //     optionLabel=""
      //     className="dropdown"
      //   />
          // audienceData?.section &&
          // <MultiSelect
          //   value={selectedSection}
          //   options={audienceData?.section}
          //   onChange={(e) => setSelectedSection(e.value)}
          //   placeholder="Select Section"
          //   optionLabel=""
          //   className="dropdown"
          //   display="chip" 
          //   maxSelectedLabels={3} 
          // />
        )} */}
        </div>
        {/* <div className="row">
        <div className="checkbox-block">
          <label>Age</label>
          <Checkbox
            checked={showAge}
            onChange={(e) => {setShowAge(e.checked)}}
            className="checkbox"
          />
        </div>
        <div className="checkbox-block">
          <label>Gender</label>
          <Checkbox
            checked={showGender}
            onChange={(e) => setShowGender(e.checked)}
            className="checkbox"
          />
        </div>
        <div className="checkbox-block">
          <label>State</label>
          <Checkbox
            checked={showStates}
            onChange={(e) => setShowStates(e.checked)}
            className="checkbox"
          />
        </div>

        {path === "/websitePlanning" ? (
          <div className="checkbox-block">
            <label>Website</label>
            <Checkbox
              checked={showWebsite}
              onChange={(e) => {
                setShowWebsite(e.checked);
                addQueryParams();
                getTableDataWebsite()
              }}
              className="checkbox"
            />
          </div>
        ) : (
          <div className="checkbox-block">
            <label>Section</label>
            <Checkbox
              checked={showSection}
              onChange={(e) => {
                setShowSection(e.checked);
              }}
              className="checkbox"
            />
          </div>
        )}
      </div> */}

        <div className="row">
          <div className="inputbox">
            <InputText
              type="text"
              onChange={inputOnChange}
              value={campaignPlanObj.noOfDays}
              name="noOfDays"
              placeholder="noOfDays"
            />
          </div>
          <div className="inputbox">
            <InputText
              type="text"
              onChange={inputOnChange}
              value={campaignPlanObj.budget}
              name="budget"
              placeholder="budget"
            />
          </div>
        </div>

        {path === "/websitePlanning" ? (
          <div className="row">
            <Button
              onClick={() => {
                addQueryParams();
                setTimeout(() => {
                  getTableDataWebsite();
                }, 1000);
              }}
              label="Show"
              className="orange-btn"
            />
          </div>
        ) : (
          <div className="row">
            <Button
              onClick={() => ShowAudiance()}
              label="Calculate"
              className="orange-btn"
              disabled={!isFormComplete}
            />
          </div>
        )}

        <div className="row">
          {showData && (
            <div className="container">
              <div className="data-field">
                <label>No. of Days: </label> {campaignPlanObj.noOfDays}
              </div>
              <div className="data-field">
                <label>Budget: </label> {campaignPlanObj.budget}
              </div>
              <div className="data-field">
                <label>APT Reach:</label> {behaviorValue}
              </div>
            </div>
          )}
        </div>

        <div className="table-container">
          {filteredData.length > 0 && (
            <DataTable
              value={filteredData}
              paginator
              responsiveLayout="scroll"
              scrollable
              scrollHeight="400px"
              selectionMode="multiple"
              selection={selectedRows}
              data={filteredData}
              onRowSelect={handleRowSelect}
              onSelectionChange={onSelectionChange}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={7}
              rowsPerPageOptions={[10, 20, 50]}>
              <Column selectionMode="multiple"></Column>
              <Column
                field="age"
                header="Age"
                sortable
              />
              <Column
                field="gender"
                header="Gender"
              />
              <Column
                field="state"
                header="State"
                sortable
              />
              <Column
                field="website"
                header="website"
                sortable
              />
              {showWebsite || selectedWebsite != "All website" ? (
                <Column
                  field="trai_reach"
                  header="APT Reach"
                  sortable
                />
              ) : null}
              {showWebsite || selectedWebsite != "All website" ? (
                <Column
                  field="avg_min_visitor"
                  header="Average Minutes"
                  sortable
                />
              ) : null}
              {showWebsite || selectedWebsite != "All website" ? (
                <Column
                  field="comosition_index_uv"
                  header="Composition Index"
                  sortable
                />
              ) : null}
            </DataTable>
          )}
        </div>

        <div className="row">
          <div className="inputbox">
            <InputText
              type="text"
              onChange={inputOnChange}
              value={campaignPlanObj.campaignName}
              name="campaignName"
              placeholder="campaignName"
            />
          </div>
          {/* <InputText type="text" onChange={inputOnChange} value={campaignPlanObj.noOfDays} name="noOfDays" placeholder="noOfDays"/> */}
          <div className="inputbox">
            <InputText
              type="text"
              onChange={inputOnChange}
              value={campaignPlanObj.objective}
              name="objective"
              placeholder="objective"
            />
          </div>
          {/* <InputText type="text" onChange={inputOnChange} value={campaignPlanObj.budget} name="budget" placeholder="budget"/> */}
        </div>

        <div className="row">
          <Button
            label="Save"
            onClick={OnSubmit}
            className="orange-btn"
            //style={{ display: isFormComplete ? 'block' : 'none' }}
            disabled={!isFormComplete2}
          />
        </div>

        {/* <div className="row">
        <Button
          label="Download Excel"
          onClick={exportExcel}
          className="orange-btn"
        />
      </div> */}
      </div>
    </>
  );
};

export default App;
